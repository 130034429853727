import type {
  QuickSurveyResponse,
  QuickSurveyBody,
} from '~/features/quick-surveys';

export const getQuickSurveys = async () => {
  const { headers } = authenticatedRequestHeaders();

  return await $fetch<QuickSurveyResponse>('/api/quick-surveys/survey', {
    method: 'GET',
    headers,
  });
};

export const createQuickSurveyAnswer = async (body: QuickSurveyBody) => {
  const { headers } = authenticatedRequestHeaders();

  return await $fetch<QuickSurvey>('/api/quick-surveys/answer', {
    method: 'POST',
    headers,
    body,
  });
};
