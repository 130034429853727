import { useQueryClient } from '@tanstack/vue-query';
import type { UserDataResponse } from '@tn/shared';
import { createCurrentUserQuery } from '~/features/auth';
import { createUserCartQuery } from '~/features/cart';
import { createCyclesQuery } from '~/features/cycles';
import { createUserOrdersQuery } from '~/features/orders';
import { createUserReviewsQuery } from '~/features/reviews/members';
import { createUserTokensQuery } from '~/features/tokens';

export default defineNuxtPlugin({
  name: 'prefetch-data',
  dependsOn: ['vue-query'],
  async setup() {
    const queryClient = useQueryClient();

    // Prefetch queries
    await Promise.all([
      queryClient.prefetchQuery(createCurrentUserQuery()),
      queryClient.prefetchQuery(createCyclesQuery()),
    ]);

    // Get user ID
    const user = queryClient.getQueryData<UserDataResponse>(['me']);
    if (user?.uid && !user?.isBrand) {
      // Prefetch user-specific queries
      await Promise.all([
        queryClient.prefetchQuery(createUserOrdersQuery(user.uid)),
        queryClient.prefetchQuery(createUserTokensQuery(user.uid)),
        queryClient.prefetchQuery(createUserCartQuery(user.uid)),
        queryClient.prefetchQuery(createUserReviewsQuery(user.uid)),
      ]);
    }
  },
});
