import Joi, { type Schema } from 'joi';
import type { QuickSurveyQuestion } from '@tn/shared';

export const QUICK_SURVEY_MAX_ANSWER_LENGTH = 500;

export const validateAnswers = (
  questions: QuickSurveyQuestion[],
  answers?: Record<string, string | string[] | boolean>
) => {
  if (!answers) {
    return { isValid: false };
  }

  const schema: Record<string, Schema> = {};

  questions.forEach((question) => {
    const answer = answers[question.questionId];

    const hasOtherOption = Array.isArray(answer) && answer.includes('other');

    schema[question.questionId] = Joi.array()
      .min(1)
      .items(Joi.string())
      .required()
      .messages({
        'any.required': 'Please select at least one option',
        'array.min': 'Please select at least one option',
      });

    if (hasOtherOption) {
      schema[`${question.questionId}Other`] = Joi.string()
        .min(1)
        .max(QUICK_SURVEY_MAX_ANSWER_LENGTH)
        .required()
        .empty('')
        .messages({
          'any.empty': `Please provide an additional text because "Other" was selected`,
          'string.min': `The "Other" answer must have at least 1 character`,
          'string.max': `The "Other" answer must not exceed 200 characters`,
          'any.required': `Please provide an additional text because "Other" was selected`,
        });
    }
  });

  const validationSchema = Joi.object(schema);

  const { error } = validationSchema.validate(answers);

  if (error) {
    const errorMessages = error.details.map((detail) => detail.message);
    return { isValid: false, errors: errorMessages };
  }

  return { isValid: true };
};
