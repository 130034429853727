import payload_plugin_e0k4tW9bdI from "/home/runner/work/taste-network/taste-network/packages/web-app/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_YTGlmQWWT5 from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xKuw1km5sV from "/home/runner/work/taste-network/taste-network/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/taste-network/taste-network/packages/web-app/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import anchorscroll_plugin_nifcvYfr4Z from "/home/runner/work/taste-network/taste-network/node_modules/nuxt-anchorscroll/dist/runtime/anchorscroll-plugin.mjs";
import plugin_client_mik7N71GmK from "/home/runner/work/taste-network/taste-network/packages/web-app/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/home/runner/work/taste-network/taste-network/packages/web-app/.nuxt/vuefire-auth-plugin.client.mjs";
import plugin_mint_cookie_client_xgxXjNHXgE from "/home/runner/work/taste-network/taste-network/packages/web-app/node_modules/nuxt-vuefire/dist/runtime/auth/plugin-mint-cookie.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/home/runner/work/taste-network/taste-network/packages/web-app/.nuxt/vuefire-plugin.mjs";
import slideovers_2NIjZ3dBbn from "/home/runner/work/taste-network/taste-network/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_FeYNAF2WLE from "/home/runner/work/taste-network/taste-network/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_mI6xh0VV9a from "/home/runner/work/taste-network/taste-network/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_x7So6BU7bH from "/home/runner/work/taste-network/taste-network/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_8brYH9QEed from "/home/runner/work/taste-network/taste-network/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import _01_cookie_pro_client_SwH7quhm2c from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/01.cookie-pro.client.ts";
import _01_prefetch_data_QckF7ZtNxi from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/01.prefetch-data.ts";
import _01_vue_query_k8pj2QgNCo from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/01.vue-query.ts";
import _02_remoteConfig_eKm3TZbKIB from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/02.remoteConfig.ts";
import _02_rudderstack_client_UJKchHqBkf from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/02.rudderstack.client.ts";
import _04_amplitudeSessionReplay_client_wYSkFadNn1 from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/04.amplitudeSessionReplay.client.ts";
import _05_fetch_brand_subscription_LDBZN3cff7 from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/05.fetch-brand-subscription.ts";
import sanitize_fbXCtPd0F2 from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/sanitize.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/sentry.client.ts";
import vue_masonry_wall_13R6vu58Yn from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/vue-masonry-wall.ts";
export default [
  payload_plugin_e0k4tW9bdI,
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  anchorscroll_plugin_nifcvYfr4Z,
  plugin_client_mik7N71GmK,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  plugin_mint_cookie_client_xgxXjNHXgE,
  vuefire_plugin_8cq4FnzXKb,
  slideovers_2NIjZ3dBbn,
  modals_FeYNAF2WLE,
  colors_mI6xh0VV9a,
  plugin_client_x7So6BU7bH,
  plugin_8brYH9QEed,
  _01_cookie_pro_client_SwH7quhm2c,
  _01_prefetch_data_QckF7ZtNxi,
  _01_vue_query_k8pj2QgNCo,
  _02_remoteConfig_eKm3TZbKIB,
  _02_rudderstack_client_UJKchHqBkf,
  _04_amplitudeSessionReplay_client_wYSkFadNn1,
  _05_fetch_brand_subscription_LDBZN3cff7,
  sanitize_fbXCtPd0F2,
  sentry_client_shVUlIjFLk,
  vue_masonry_wall_13R6vu58Yn
]