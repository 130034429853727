import { defineStore } from 'pinia';
import BrandPaywallModal from '@/components/brands/PaywallModal.vue';
import {
  BrandBasicPlans,
  BrandFullPlans,
  isSubscriptionActiveOrInGracePeriod,
  type BrandSubscriptionPlan,
  type CancelSubscriptionSurvey,
  type FastSpringSubscription,
} from '@tn/shared';
import { getCycleFromMsTimestamp, useCyclesData } from '~/features/cycles';

export const useBrandSubscriptionStore = defineStore(
  'brandSubscription',
  () => {
    const modal = useModal();
    const { brandUser, testClockTimeMs } = useUserState();
    const { cycles, currentCycle } = useCyclesData();
    const currentSubscription = ref<FastSpringSubscription | null>(null);
    const accessUntilDate = ref<number | null>(null);
    const activeOrGracePeriodSubscription = ref(false);
    const isFetchingCurrentSubscription = ref(false);
    const isCancellingCurrentSubscription = ref(false);
    const isSavingCancelSurvey = ref(false);
    const { $sentry } = useNuxtApp();
    const notify = useNotifications();
    const isUpdatingSubscription = ref(false);

    // Getters
    const isOnTestClock = computed(() => !!testClockTimeMs.value);
    const subscriptionPlan = computed(
      () => currentSubscription.value?.product as BrandSubscriptionPlan | null
    );

    const hasBasicPlan = computed(() => {
      if (
        isOnTestClock.value &&
        brandUser?.value?.subscription?.plan === 'basecamp'
      ) {
        return true;
      }

      const plan = currentSubscription.value?.customMetadata
        .currentActiveProductId as BrandSubscriptionPlan | null;
      return Boolean(plan && BrandBasicPlans.includes(plan));
    });

    const hasFullPlan = computed(() => {
      if (
        isOnTestClock.value &&
        brandUser?.value?.subscription?.plan === 'full'
      ) {
        return true;
      }

      const plan = currentSubscription.value?.customMetadata
        .currentActiveProductId as BrandSubscriptionPlan | null;
      return Boolean(plan && BrandFullPlans.includes(plan));
    });

    const hasActiveSubscription = computed(() => {
      if (
        isOnTestClock.value &&
        brandUser?.value?.subscription?.status === 'active'
      ) {
        return true;
      }

      return !!currentSubscription.value?.active;
    });

    const hasActiveOrGracePeriodSubscription = computed(() => {
      if (
        isOnTestClock.value &&
        (brandUser?.value?.subscription?.status === 'active' ||
          brandUser?.value?.subscription?.status === 'grace')
      ) {
        return true;
      }

      return !!activeOrGracePeriodSubscription.value;
    });

    const hasActiveOrGracePeriodFullSubscription = computed(() =>
      Boolean(hasActiveOrGracePeriodSubscription.value && hasFullPlan.value)
    );

    const cancelledSubscriptionEndDate = computed(() => {
      if (
        currentSubscription?.value?.state !== 'canceled' ||
        !currentSubscription.value?.deactivationDate
      ) {
        return;
      }

      const endCycle = getCycleFromMsTimestamp(
        cycles.value,
        currentSubscription.value?.deactivationDate
      );

      return endCycle?.endDate;
    });

    const isSubscriptionCanceled = computed(() => {
      if (
        isOnTestClock.value &&
        brandUser?.value?.subscription?.status === 'canceled'
      ) {
        return true;
      }

      return (
        currentSubscription?.value &&
        currentSubscription?.value.state === 'canceled'
      );
    });

    const canViewDashboardData = computed(() => {
      return Boolean(
        hasActiveOrGracePeriodSubscription.value || isSubscriptionCanceled.value
      );
    });

    // Actions
    async function fetchCurrentSubscription() {
      const { headers } = authenticatedRequestHeaders();

      isFetchingCurrentSubscription.value = true;
      const subscription = await $fetch<FastSpringSubscription | null>(
        '/api/fastspring/current-subscription',
        { headers }
      );
      isFetchingCurrentSubscription.value = false;

      if (!currentCycle.value || !subscription) {
        activeOrGracePeriodSubscription.value = false;
        accessUntilDate.value = null;
        return;
      }

      currentSubscription.value = {
        ...subscription,
      };

      const isActive = isSubscriptionActiveOrInGracePeriod({
        subscription: currentSubscription.value,
        currentCycle: currentCycle.value,
      });
      activeOrGracePeriodSubscription.value = isActive;
      accessUntilDate.value = isActive ? currentCycle.value.endDate : null;
    }

    const updateSubscription = async (productId: BrandSubscriptionPlan) => {
      const subscriptionId = currentSubscription.value?.id;

      try {
        if (!productId) {
          throw new Error('Error, product id is required');
        }

        isUpdatingSubscription.value = true;
        const response = await $fetch(
          `/api/fastspring/subscriptions/${subscriptionId}`,
          {
            method: 'POST',
            body: {
              productId: productId,
            },
          }
        );

        currentSubscription.value = response
          ? (response as FastSpringSubscription)
          : null;
      } catch (error: any) {
        notify.showErrorNotification({
          description: error?.message ?? 'Error could not update subscription',
        });
        $sentry.captureException(error);
      } finally {
        isUpdatingSubscription.value = false;
      }
    };

    const refreshSubscriptionById = async (subscriptionId: string) => {
      try {
        isFetchingCurrentSubscription.value = true;
        const response = await $fetch(
          `/api/fastspring/subscriptions/${subscriptionId}`,
          { method: 'GET' }
        );

        currentSubscription.value = response
          ? (response as FastSpringSubscription)
          : null;
      } catch (error: any) {
        notify.showErrorNotification({
          description: error?.message ?? 'Error could not get the subscription',
        });
        $sentry.captureException(error);
      } finally {
        isFetchingCurrentSubscription.value = false;
      }
    };

    const cancelFastspringSubscription = async () => {
      const subscriptionId = currentSubscription?.value?.id;
      const productId = currentSubscription.value?.product;
      try {
        isCancellingCurrentSubscription.value = true;
        const response = await $fetch(
          `/api/fastspring/subscriptions/${subscriptionId}`,
          {
            method: 'DELETE',
            body: {
              productId: productId,
            },
          }
        );

        currentSubscription.value = response
          ? (response as FastSpringSubscription)
          : null;
      } catch (error: any) {
        notify.showErrorNotification({
          description:
            error?.message ?? 'Error could not cancel the subscription',
        });
        $sentry.captureException(error);
      } finally {
        isCancellingCurrentSubscription.value = false;
      }
    };

    const saveCancelSubscriptionSurvey = async (
      cancelSubscriptionSurvey: Partial<CancelSubscriptionSurvey>
    ) => {
      const brandId = brandUser.value?.brand.id;
      const subscriptionId = currentSubscription.value?.id;

      const { productId, cancelReason, cancelReasonOther } =
        cancelSubscriptionSurvey;
      const body: Partial<CancelSubscriptionSurvey> = {
        subscriptionId,
        productId,
        ...(cancelReason && { cancelReason }),
        ...(cancelReasonOther && { cancelReasonOther }),
      };

      try {
        isSavingCancelSurvey.value = true;
        await $fetch(`/api/brands/${brandId}/cancel-subscription-survey`, {
          method: 'POST',
          body: body,
        });
      } catch (error: any) {
        notify.showErrorNotification({
          description: error?.message ?? 'Error could not save the survey',
        });
        $sentry.captureException(error);
      } finally {
        isSavingCancelSurvey.value = false;
      }
    };

    const openPaywallModal = () => {
      modal.open(BrandPaywallModal);
    };

    return {
      // State
      currentSubscription,
      accessUntilDate,
      isFetchingCurrentSubscription,
      isUpdatingSubscription,
      isCancellingCurrentSubscription,
      isSavingCancelSurvey,

      // Getters
      subscriptionPlan,
      hasBasicPlan,
      hasFullPlan,
      hasActiveSubscription,
      hasActiveOrGracePeriodSubscription,
      hasActiveOrGracePeriodFullSubscription,
      cancelledSubscriptionEndDate,
      isSubscriptionCanceled,
      isOnTestClock,
      canViewDashboardData,

      // Actions
      fetchCurrentSubscription,
      openPaywallModal,
      updateSubscription,
      refreshSubscriptionById,
      cancelFastspringSubscription,
      saveCancelSubscriptionSurvey,
    };
  }
);
