<script setup lang="ts">
import { useQuickSurveys } from '~/features/quick-surveys';
import { QuickSurvey } from '#components';

const {
  canShowQuickSurvey,
  quickSurvey,
  submitQuickSurveyAnswer,
  dismissQuickSurvey,
} = useQuickSurveys();

const localQuickSurveyAnswer = ref<
  { [key: string]: string | string[] | boolean } | undefined
>();

const onUpdateAnswer = (answer?: {
  [key: string]: string | boolean | string[];
}) => {
  localQuickSurveyAnswer.value = {
    ...localQuickSurveyAnswer?.value,
    ...answer,
  };

  Object.keys(answer || {}).forEach((key) => {
    if (answer && answer[key] === undefined && localQuickSurveyAnswer?.value) {
      delete localQuickSurveyAnswer?.value[key];
    }
  });
};

const actionsMap: Record<
  string,
  (answer?: { [key: string]: string | boolean | string[] }) => void
> = {
  dismiss: dismissQuickSurvey,
  update: onUpdateAnswer,
  submit: () => submitQuickSurveyAnswer(localQuickSurveyAnswer.value),
};

const onActionClicked = async ({
  actionType,
  answer,
}: {
  actionType: 'dismiss' | 'submit' | 'update';
  answer?: { [key: string]: string | boolean | string[] };
}) => {
  actionsMap[actionType]?.(answer);
};
</script>

<template>
  <QuickSurvey
    v-if="quickSurvey && canShowQuickSurvey"
    :quick-survey="quickSurvey"
    @action-clicked="onActionClicked"
  />
</template>
