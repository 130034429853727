import { signOut } from 'firebase/auth';

export function useImpersonation() {
  const isImpersonating = useState('isImpersonating', () => false);
  const auth = useFirebaseAuth()!;
  const isStoppingImpersonation = ref(false);

  const checkImpersonation = async () => {
    if (!auth?.currentUser?.uid) {
      isImpersonating.value = false;
      return;
    }

    try {
      const idTokenResult = await auth?.currentUser?.getIdTokenResult();
      const impersonatedBy = idTokenResult?.claims.impersonatedBy;

      isImpersonating.value = !!impersonatedBy;
    } catch (error) {
      isImpersonating.value = false;
    }
  };

  const stopImpersonating = async () => {
    isStoppingImpersonation.value = true;
    await signOut(auth);
    window.location.href = '/login';
  };

  return {
    isStoppingImpersonation,
    isImpersonating,
    checkImpersonation,
    stopImpersonating,
  };
}
