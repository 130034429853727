import {
  useCreateQuickSurveyAnswerMutation,
  useQuickSurveyQuery,
  validateAnswers,
} from '~/features/quick-surveys';
import EVENTS from '~/constants/events';

export function useQuickSurveys() {
  const route = useRoute();
  const notification = useNotifications();
  const { isBrand, brandUser } = useUserState();
  const { $trackEvent } = useNuxtApp();

  const { data: quickSurvey } = useQuickSurveyQuery();
  const { mutateAsync: createQuickSurveyMutation } =
    useCreateQuickSurveyAnswerMutation();

  const trackAnalytics = (
    eventName: string,
    options?: {
      [key: string]: string | string[] | boolean;
    }
  ) => {
    const questionIds = quickSurvey.value?.questions.map(
      (question) => question.questionId
    );

    $trackEvent(eventName, {
      survey_id: quickSurvey.value?.id,
      ...(isBrand.value ? { brand_id: brandUser.value?.brand.id } : {}),
      ...(isBrand.value ? { brand_name: brandUser.value?.brand.name } : {}),
      ...(questionIds
        ? { question: questionIds?.length > 1 ? questionIds : questionIds[0] }
        : {}),
      ...options,
    });
  };

  const submitQuickSurveyAnswer = async (answer?: {
    [key: string]: string | string[] | boolean;
  }) => {
    const { isValid, errors } = validateAnswers(
      quickSurvey?.value?.questions || [],
      answer
    );

    if (!isValid) {
      notification.showErrorNotification({
        description:
          errors?.join(', ') || 'Please answer the survey questions.',
      });
      return;
    }

    if (!answer || !quickSurvey?.value?.id) {
      return;
    }

    let analyticsAnswer: string | string[] | boolean | undefined;
    let analyticsAnswerOther: string | undefined;

    Object.entries(answer).map(([key, value]) => {
      if (key.endsWith('Other') && value && typeof value === 'string') {
        analyticsAnswerOther = value;
      }
      if (value) {
        analyticsAnswer = value;
      }
    });

    const eventName = isBrand.value
      ? EVENTS.BRAND_QUICK_POOL_ANSWERED
      : EVENTS.MEMBER_QUICK_POOL_ANSWERED;

    trackAnalytics(eventName, {
      ...(analyticsAnswer ? { response: analyticsAnswer } : {}),
      ...(analyticsAnswerOther ? { response_other: analyticsAnswerOther } : {}),
    });

    await createQuickSurveyMutation({
      surveyId: quickSurvey.value?.id,
      answers: answer,
    });
  };

  const dismissQuickSurvey = async () => {
    if (!quickSurvey?.value?.id) {
      return;
    }

    trackAnalytics(EVENTS.MEMBER_QUICK_POOL_DISMISSED);

    await createQuickSurveyMutation({
      surveyId: quickSurvey.value?.id,
      dismissCount: 1,
    });
  };

  const canShowQuickSurvey = computed(() => {
    if (!quickSurvey?.value?.targetOptions?.urlPattern && quickSurvey?.value) {
      return true;
    }

    const targetPath = quickSurvey?.value?.targetOptions?.urlPattern;

    return targetPath ? route.path === targetPath : false;
  });

  watch(
    () => quickSurvey.value && canShowQuickSurvey.value,
    (shouldTriggerViewedEvent) => {
      if (shouldTriggerViewedEvent) {
        const eventName = isBrand.value
          ? EVENTS.BRAND_QUICK_POOL_VIEWED
          : EVENTS.MEMBER_QUICK_POOL_VIEWED;
        trackAnalytics(eventName);
      }
    }
  );

  return {
    canShowQuickSurvey,
    quickSurvey,
    submitQuickSurveyAnswer,
    dismissQuickSurvey,
  };
}
