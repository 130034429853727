import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { createQuickSurveyAnswer, type QuickSurveyBody } from '../api';
import type { QuickSurveyAnswer } from '@tn/shared';

export const useCreateQuickSurveyAnswerMutation = () => {
  const queryClient = useQueryClient();
  const queryKey = ['quickSurvey'];
  const notification = useNotifications();

  return useMutation({
    mutationFn: async (body: QuickSurveyBody) => {
      return await createQuickSurveyAnswer(body);
    },
    onMutate: async (item) => {
      const isDismissSurvey = Object.keys(item).includes('dismissCount');

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey });
      // Snapshot the previous value
      const previousQuickSurvey = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(
        queryKey,

        // Remove the QuickSurvey from state
        (old: Partial<QuickSurveyAnswer> | undefined) => null
      );

      notification.showSuccessNotification({
        description: `Survey ${isDismissSurvey ? 'dismissed' : 'submitted'} successfully.`,
      });

      // Return a context object with the snapshotted value
      return { previousQuickSurvey };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newCart, context) => {
      notification.showErrorNotification({
        description:
          err?.message || 'Something went wrong, please try again later.',
      });

      queryClient.setQueryData(queryKey, context?.previousQuickSurvey ?? {});
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
