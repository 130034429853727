import { useTestClockBrandsQuery } from '../queries';

export function useTestClockBrands() {
  const { data, isLoading, suspense, error } = useTestClockBrandsQuery();
  const brands = computed(() => data.value || []);

  return {
    brands,
    isLoading,
    error,
    suspense,
  };
}
