import type { Token } from '@tn/shared';

interface FetchUserTokensResponse {
  tokens: Token[];
}

export async function fetchUserTokens(userId: string) {
  const { headers } = authenticatedRequestHeaders();

  return await $fetch<FetchUserTokensResponse>(`/api/users/${userId}/tokens`, {
    headers: {
      ...headers,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
    cache: 'no-cache',
  });
}
