import * as rudderanalytics from 'rudder-sdk-js';
import type { apiCallback, apiObject, apiOptions } from 'rudder-sdk-js';
import getSessionReplayProperties from '~/helpers/getSessionReplayProperties';
import { CookieProCategoryId } from '~/plugins/01.cookie-pro.client';

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  const { $cookiePro } = useNuxtApp();
  const { isImpersonating } = useImpersonation();

  const isPerformanceCookieEnabled = (): boolean => {
    return $cookiePro?.cookiesState[CookieProCategoryId.Performance] ?? false;
  };

  const initRudderstack = () => {
    rudderanalytics.load(
      config.public.rudderstackWriteKey,
      config.public.rudderstackDataPlane,
      {
        cookieConsentManager: {
          oneTrust: {
            enabled: true,
          },
        },
      }
    );
  };

  $cookiePro.onConsentUpdated(() => {
    if (isPerformanceCookieEnabled()) {
      initRudderstack();
    }
  });

  rudderanalytics.ready(() => {
    console.info('Rudderstack Ready');
  });

  const deviceId = rudderanalytics.getAnonymousId();

  const getSessionId = () => {
    return rudderanalytics.getSessionId();
  };

  useState('deviceId', () => deviceId);

  const identifyEvent = (
    userId: string,
    traits: apiObject,
    options?: apiOptions
  ) => {
    if (rudderanalytics && isPerformanceCookieEnabled()) {
      console.info('Identify event | ', userId, traits, options || {});
      rudderanalytics.identify(userId, traits, options);
    }
  };

  const trackEvent = (event: string, data?: Record<string, any>) => {
    const properties = getSessionReplayProperties(getSessionId());
    const computedData = properties ? { ...data, ...properties } : data;

    if (isImpersonating.value) {
      return;
    }

    if (!$cookiePro?.cookiesState[CookieProCategoryId.Performance]) {
      return;
    }

    if (rudderanalytics && isPerformanceCookieEnabled()) {
      console.info('Track event | ', event, computedData || {});
      rudderanalytics.track(event, computedData);
    }
  };

  const resetEvent = () => {
    if (rudderanalytics && isPerformanceCookieEnabled()) {
      console.info('Reset event | ');
      rudderanalytics.reset(true);
    }
  };

  const pageEvent = ({
    name,
    category,
    properties,
    options,
    callback,
  }: {
    name?: string;
    category?: string;
    properties?: apiObject;
    options?: apiOptions;
    callback?: apiCallback;
  }) => {
    if (rudderanalytics && isPerformanceCookieEnabled()) {
      console.info('Page event');
      rudderanalytics.page(category, name, properties, options, callback);
    }
  };

  return {
    provide: {
      trackEvent,
      identifyEvent,
      resetEvent,
      pageEvent,
      getSessionId,
    },
  };
});
