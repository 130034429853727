import type { BrandUserRole } from '@tn/shared';

export const brandUserRoles: {
  id: BrandUserRole;
  label: string;
}[] = [
  {
    id: 'brand-admin',
    label: 'Admin',
  },
];

export const subscriptionPlans = [
  {
    id: 'basecamp',
    label: 'Basecamp',
  },
  {
    id: 'full',
    label: 'Full',
  },
];

export const subscriptionStatuses = [
  {
    id: 'active',
    label: 'Active',
  },
  {
    id: 'grace',
    label: 'Grace Period',
  },
  {
    id: 'canceled',
    label: 'Canceled',
  },
  {
    id: 'none',
    label: 'None',
  },
];
