import { useQuery } from '@tanstack/vue-query';
import { getBrandsForTestClock } from '../api';

export const createTestClockBrandsQuery = () => ({
  queryKey: ['test-clock', 'brands'],
  queryFn: async () => {
    return await getBrandsForTestClock();
  },
  placeholderData: [],
  staleTime: 1000 * 60 * 60 * 1, // 1 hour
});

export function useTestClockBrandsQuery() {
  return useQuery(createTestClockBrandsQuery());
}
