<script setup lang="ts">
import type { BrandOnboardingStepOption } from '@tn/shared';

const emits = defineEmits(['update-value']);

const props = defineProps<{
  questionId: string;
  options: BrandOnboardingStepOption[];
}>();

const selectedOptions = reactive<string[]>([]);

const onOptionChanged = (optionValue: {
  value: string;
  shouldAdd: boolean;
}) => {
  if (optionValue.value === 'other') {
    emits('update-value', {
      [`${props.questionId}Other`]: optionValue.shouldAdd ? '' : undefined,
    });
  }

  if (optionValue.shouldAdd && !selectedOptions?.includes(optionValue.value)) {
    selectedOptions.push(optionValue.value);
  } else {
    const index = selectedOptions.indexOf(optionValue.value);
    if (index !== -1) {
      selectedOptions.splice(index, 1);
    }
  }

  emits('update-value', {
    [props.questionId]: selectedOptions,
  });
};

const isGridLayout = computed(() => {
  const MAX_LABEL_LENGTH = 20;

  return props.options.every(
    (option) => option.label.trim().length <= MAX_LABEL_LENGTH
  );
});

const inputText = ref();

const onInputChange = (textValue: string) => {
  emits('update-value', {
    [`${props.questionId}Other`]: textValue,
  });
};

const canShowForm = computed(() => selectedOptions?.includes('other'));
</script>

<template>
  <div
    v-if="options"
    :class="isGridLayout ? 'grid grid-cols-2 gap-2' : 'flex flex-col space-y-2'"
  >
    <UCheckbox
      v-for="option in options"
      :key="option.value"
      :model-value="selectedOptions?.includes(option.value)"
      @change="onOptionChanged({ value: option.value, shouldAdd: $event })"
    >
      <template #label>
        <div>
          <span class="text-tnNeutral-700 text-sm font-medium">{{
            option.label
          }}</span>
        </div>
        <span
          v-if="option.labelInfo"
          class="text-tnNeutral-400 text-xs font-medium"
          >{{ option.labelInfo }}</span
        >
      </template>
    </UCheckbox>
    <TnTextArea
      v-if="canShowForm"
      :model-value="inputText"
      placeholder="Please specify"
      @update:model-value="onInputChange"
    />
  </div>
</template>
