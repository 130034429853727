import { default as index7Cj5RBpO6IMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/index.vue?macro=true";
import { default as ratingsrKOz1k122rMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/ratings.vue?macro=true";
import { default as reviews0xAYkTRLV6Meta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/reviews.vue?macro=true";
import { default as _91id_93hSPKrcQKDxMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/onboarding/[id].vue?macro=true";
import { default as email_45verification72PyZNOf3KMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/onboarding/email-verification.vue?macro=true";
import { default as index5BMmmG2KUrMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/orders/index.vue?macro=true";
import { default as archivedlydkzSx1nzMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/archived.vue?macro=true";
import { default as _91productId_93zZ06AbBHTOMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/edit/[productId].vue?macro=true";
import { default as indexhKWZG7IgyLMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/index.vue?macro=true";
import { default as _91promotionId_93Der6nV1ptbMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/promotions/edit/[promotionId].vue?macro=true";
import { default as expired0QWDemRcPuMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/promotions/expired.vue?macro=true";
import { default as indexuqvX7bqGGoMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/promotions/index.vue?macro=true";
import { default as detailsnWObse1EtcMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/settings/details.vue?macro=true";
import { default as indexGoHDyE1PvgMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/settings/index.vue?macro=true";
import { default as integrationstbsRhdKCiKMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/settings/integrations.vue?macro=true";
import { default as usersJxQu9MQhhNMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/settings/users.vue?macro=true";
import { default as signupUBKOrf8EKCMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/signup.vue?macro=true";
import { default as indexLcbhokUGRiMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/index.vue?macro=true";
import { default as helpyFoVlJB94GMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/help.vue?macro=true";
import { default as indexf8V4vm1fsPMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/index.vue?macro=true";
import { default as magicHsby3aGn34Meta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/magic.vue?macro=true";
import { default as tokenJNDRynOlIPMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/token.vue?macro=true";
import { default as checkout9f6juQbjmLMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/checkout.vue?macro=true";
import { default as earndRUCAic0ZhMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/earn.vue?macro=true";
import { default as how_45it_45worksWxpozaUFobMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/how-it-works.vue?macro=true";
import { default as indexdXptrUx01JMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/index.vue?macro=true";
import { default as _91id_93l8GS7peGuiMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/[id].vue?macro=true";
import { default as check_45emaildY2lJcuyBoMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/check-email.vue?macro=true";
import { default as signup3WZa8HjPSzMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/signup.vue?macro=true";
import { default as startbGZSOFZ6CRMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/start.vue?macro=true";
import { default as _91ids_93obDBKLELUDMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/orders/confirmation/[ids].vue?macro=true";
import { default as _91id_93c6ZpNnXzEBMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/products/[id].vue?macro=true";
import { default as index6qLuYlFNuKMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/products/index.vue?macro=true";
import { default as promotionsLX6Pc0YvGvMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/promotions.vue?macro=true";
import { default as _91id_93n0rylQ2TpRMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[reviewId]/[id].vue?macro=true";
import { default as startWHoVUOfDmFMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[reviewId]/start.vue?macro=true";
import { default as thank_45youN3NLrazN2fMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[reviewId]/thank-you.vue?macro=true";
import { default as waitlistbOLjq1crCkMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/waitlist.vue?macro=true";
import { default as walletcpFODNgA2wMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/wallet.vue?macro=true";
import { default as component_45stubKVTE2qwsvaMeta } from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubKVTE2qwsva } from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "brands-dashboard",
    path: "/brands/dashboard",
    meta: index7Cj5RBpO6IMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/index.vue")
  },
  {
    name: "brands-dashboard-ratings",
    path: "/brands/dashboard/ratings",
    meta: ratingsrKOz1k122rMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/ratings.vue")
  },
  {
    name: "brands-dashboard-reviews",
    path: "/brands/dashboard/reviews",
    meta: reviews0xAYkTRLV6Meta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/reviews.vue")
  },
  {
    name: "brands-onboarding-step",
    path: "/brands/onboarding/:id()",
    meta: _91id_93hSPKrcQKDxMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/onboarding/[id].vue")
  },
  {
    name: "brands-onboarding-email-verification",
    path: "/brands/onboarding/email-verification",
    meta: email_45verification72PyZNOf3KMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/onboarding/email-verification.vue")
  },
  {
    name: "brands-orders",
    path: "/brands/orders",
    meta: index5BMmmG2KUrMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/orders/index.vue")
  },
  {
    name: "brands-products-archived",
    path: "/brands/products/archived",
    meta: archivedlydkzSx1nzMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/archived.vue")
  },
  {
    name: "brands-products-edit",
    path: "/brands/products/edit/:productId()",
    meta: _91productId_93zZ06AbBHTOMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/edit/[productId].vue")
  },
  {
    name: "brands-products",
    path: "/brands/products",
    meta: indexhKWZG7IgyLMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/index.vue")
  },
  {
    name: "brands-promotions-edit",
    path: "/brands/promotions/edit/:promotionId()",
    meta: _91promotionId_93Der6nV1ptbMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/promotions/edit/[promotionId].vue")
  },
  {
    name: "brands-promotions-expired",
    path: "/brands/promotions/expired",
    meta: expired0QWDemRcPuMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/promotions/expired.vue")
  },
  {
    name: "brands-promotions",
    path: "/brands/promotions",
    meta: indexuqvX7bqGGoMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/promotions/index.vue")
  },
  {
    name: "brands-account-details",
    path: "/brands/settings/details",
    meta: detailsnWObse1EtcMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/settings/details.vue")
  },
  {
    name: "brands-subscription",
    path: "/brands/settings",
    meta: indexGoHDyE1PvgMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/settings/index.vue")
  },
  {
    name: "brands-integrations",
    path: "/brands/settings/integrations",
    meta: integrationstbsRhdKCiKMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/settings/integrations.vue")
  },
  {
    name: "brands-users",
    path: "/brands/settings/users",
    meta: usersJxQu9MQhhNMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/settings/users.vue")
  },
  {
    name: "brands-signup",
    path: "/brands/signup",
    meta: signupUBKOrf8EKCMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/signup.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/index.vue")
  },
  {
    name: "member-reset-password",
    path: "/login/help",
    meta: helpyFoVlJB94GMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/help.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexf8V4vm1fsPMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/index.vue")
  },
  {
    name: "login-magic",
    path: "/login/magic",
    meta: magicHsby3aGn34Meta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/magic.vue")
  },
  {
    name: "login-token",
    path: "/login/token",
    meta: tokenJNDRynOlIPMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/token.vue")
  },
  {
    name: "checkout",
    path: "/members/checkout",
    meta: checkout9f6juQbjmLMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/checkout.vue")
  },
  {
    name: "members-earn",
    path: "/members/earn",
    meta: earndRUCAic0ZhMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/earn.vue")
  },
  {
    name: "how-it-works",
    path: "/members/how-it-works",
    meta: how_45it_45worksWxpozaUFobMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/how-it-works.vue")
  },
  {
    name: "home",
    path: "/members",
    meta: indexdXptrUx01JMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/index.vue")
  },
  {
    name: "member-onboarding-question",
    path: "/members/onboarding/:id()",
    meta: _91id_93l8GS7peGuiMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/[id].vue")
  },
  {
    name: "onboarding-check-email",
    path: "/members/onboarding/check-email",
    meta: check_45emaildY2lJcuyBoMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/check-email.vue")
  },
  {
    name: "member-signup",
    path: "/members/onboarding/signup",
    meta: signup3WZa8HjPSzMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/signup.vue")
  },
  {
    name: "member-onboarding-start",
    path: "/members/onboarding/start",
    meta: startbGZSOFZ6CRMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/start.vue")
  },
  {
    name: "order-confirmation",
    path: "/members/orders/confirmation/:ids()",
    meta: _91ids_93obDBKLELUDMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/orders/confirmation/[ids].vue")
  },
  {
    name: "product",
    path: "/members/products/:id()",
    meta: _91id_93c6ZpNnXzEBMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/products/[id].vue")
  },
  {
    name: "products",
    path: "/members/products",
    meta: index6qLuYlFNuKMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/products/index.vue")
  },
  {
    name: "promotions",
    path: "/members/promotions",
    meta: promotionsLX6Pc0YvGvMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/promotions.vue")
  },
  {
    name: "members-review-question",
    path: "/members/review/:reviewId()/:id()",
    meta: _91id_93n0rylQ2TpRMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[reviewId]/[id].vue")
  },
  {
    name: "members-review-start",
    path: "/members/review/:reviewId()/start",
    meta: startWHoVUOfDmFMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[reviewId]/start.vue")
  },
  {
    name: "members-review-thank-you",
    path: "/members/review/:reviewId()/thank-you",
    meta: thank_45youN3NLrazN2fMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[reviewId]/thank-you.vue")
  },
  {
    name: "member-waitlist",
    path: "/members/waitlist",
    meta: waitlistbOLjq1crCkMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/waitlist.vue")
  },
  {
    name: "wallet",
    path: "/members/wallet",
    meta: walletcpFODNgA2wMeta || {},
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/wallet.vue")
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/brands",
    component: component_45stubKVTE2qwsva
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/onboarding",
    component: component_45stubKVTE2qwsva
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/waitlist",
    component: component_45stubKVTE2qwsva
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/members/onboarding",
    component: component_45stubKVTE2qwsva
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/onboarding/signup",
    component: component_45stubKVTE2qwsva
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/onboarding/check-email",
    component: component_45stubKVTE2qwsva
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/products",
    component: component_45stubKVTE2qwsva
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/checkout",
    component: component_45stubKVTE2qwsva
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/how-it-works",
    component: component_45stubKVTE2qwsva
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/wallet",
    component: component_45stubKVTE2qwsva
  },
  {
    name: component_45stubKVTE2qwsvaMeta?.name,
    path: "/members/login",
    component: component_45stubKVTE2qwsva
  }
]