<script setup lang="ts">
import { type QuickSurvey, UserType } from '@tn/shared';
import MultiSelectCheckbox from '~/components/quick-survey/MultiSelectCheckbox.vue';
import pluralize from 'pluralize';

defineProps<{
  quickSurvey: QuickSurvey;
}>();

const breakpoints = useBreakpoints({
  mobile: 0,
  tablet: 640,
  laptop: 1024,
  desktop: 1280,
});

const isMobile = breakpoints.smaller('laptop');
const isAnswerNowPressed = ref(false);

const quickSurveyComponents: Record<string, Component> = {
  'multi-select-checkbox': MultiSelectCheckbox,
};

const canShowPreviewSurvey = computed(
  () => isMobile.value && !isAnswerNowPressed.value
);

const emits = defineEmits(['action-clicked']);

const onSubmitSurvey = () => {
  if (canShowPreviewSurvey.value) {
    isAnswerNowPressed.value = true;
    return;
  }

  emits('action-clicked', { actionType: 'submit' });
};
</script>

<template>
  <ClientOnly>
    <div
      class="bg-beige-300 fixed bottom-4 z-50 mx-2 max-w-[420px] rounded-[20px] border border-b-4 border-black p-4 sm:bottom-10 sm:right-10 sm:m-0"
    >
      <UButton
        color="primary"
        size="xs"
        icon="i-heroicons-x-mark"
        class="absolute right-4 top-4 h-6 w-6 p-[3px]"
        @click="$emit('action-clicked', { actionType: 'dismiss' })"
      />
      <div
        v-if="quickSurvey.targetOptions.userType === UserType.Brand"
        class="mb-2 max-w-[148px] rounded-sm border border-b-2 border-black bg-yellow-500 py-1 text-center"
      >
        <p class="text-sm font-medium">QUICK POLL</p>
      </div>
      <div
        v-if="quickSurvey"
        v-for="question in quickSurvey.questions"
        :key="question.title"
      >
        <h2
          class="font-poppins mb-1 mr-12 max-w-[340px] text-lg font-bold"
          :class="[question.subtitle ? 'mb-1' : 'mb-4']"
        >
          {{ question.title }}
        </h2>
        <p
          v-if="question.subtitle && !canShowPreviewSurvey"
          class="mb-2 text-sm font-medium text-neutral-400"
        >
          {{ question.subtitle }}
        </p>
        <p
          v-if="canShowPreviewSurvey"
          class="mb-4 text-sm font-medium text-neutral-400"
        >
          {{
            `${quickSurvey.questions.length} ${pluralize('Question', quickSurvey.questions.length)}`
          }}
        </p>
        <component
          v-if="!canShowPreviewSurvey"
          :class="[quickSurvey.questions.length > 1 ? 'mb-4' : undefined]"
          :is="quickSurveyComponents[question.questionType]"
          :question-id="question.questionId"
          :options="question.options"
          @update-value="
            $emit('action-clicked', { actionType: 'update', answer: $event })
          "
        />
      </div>
      <div class="mt-4 flex flex-col items-center gap-2">
        <UButton
          block
          :label="canShowPreviewSurvey ? 'Answer Now' : 'Submit'"
          :color="canShowPreviewSurvey ? 'secondary' : 'tertiary'"
          @click="onSubmitSurvey"
        />
        <UButton
          v-if="!canShowPreviewSurvey"
          block
          label="Dismiss"
          variant="ghost"
          color="secondary"
          class="text-blue-500"
          @click="$emit('action-clicked', { actionType: 'dismiss' })"
        />
      </div>
    </div>
  </ClientOnly>
</template>
