import type { ProductSurveyAnswers } from '@tn/shared';
import type { SaveReviewAnswerBody, StartReviewBody } from './types';

interface FetchUserReviewsResponse {
  reviews: ProductSurveyAnswers[];
}

export async function fetchUserReviews(userId: string) {
  const { headers } = authenticatedRequestHeaders();

  return await $fetch<FetchUserReviewsResponse>(
    `/api/users/${userId}/reviews`,
    {
      headers,
    }
  );
}

export async function startSurveyReview(body: StartReviewBody) {
  return await $fetch(`/api/reviews/start`, {
    method: 'POST',
    body,
  });
}

export async function saveReviewAnswer({
  reviewId,
  answers,
}: SaveReviewAnswerBody) {
  return await $fetch(`/api/reviews/${reviewId}/save`, {
    method: 'POST',
    body: {
      answers,
    },
  });
}

export async function deleteReview(reviewId: string) {
  const { headers } = authenticatedRequestHeaders();

  return await $fetch(`/api/reviews/${reviewId}`, {
    method: 'DELETE',
    headers,
  });
}

export async function completeReview(reviewId: string) {
  return await $fetch(`/api/reviews/${reviewId}/complete`, {
    method: 'POST',
  });
}
