import { useFirestore, useDocument } from 'vuefire';
import { doc } from 'firebase/firestore';
import { watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

export function useTriggerOperations(userId?: string | null) {
  if (!userId) {
    return;
  }

  const queryClient = useQueryClient();
  const db = useFirestore();
  const triggerDoc = useDocument(doc(db, 'triggerOperations', userId));

  const watchers: Array<() => void> = [];

  const watchTrigger = (key: string, callback: (newValue: any) => void) => {
    const stop = watch(() => triggerDoc.value?.[key]?.status, callback);
    watchers.push(stop);
  };

  const clearUserTokens = () => {
    queryClient.invalidateQueries({
      queryKey: ['users', userId, 'tokens'],
      exact: true,
    });
  };

  watchTrigger('tokens.create', (newStatus) => {
    if (newStatus === 'completed') {
      clearUserTokens();
    }
  });

  watchTrigger('testClock.delete', (newStatus) => {
    if (newStatus === 'completed') {
      clearUserTokens();
    }
  });

  // Cleanup
  return () => {
    watchers.forEach((stop) => stop());
  };
}
