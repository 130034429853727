import { useOrders } from '~/features/orders';
import { useUserReviewsQuery } from '../queries';
import { useCyclePeriods, useCyclesData } from '~/features/cycles';

export function useUserReviews() {
  const { uid } = useUserState();
  const { data, isLoading, suspense, error } = useUserReviewsQuery(uid.value);
  const { currentCycleOrders } = useOrders();
  const { currentCycle } = useCyclesData();
  const { isReviewPeriodOpen } = useCyclePeriods();
  const reviews = computed(() => data.value || []);

  const completedReviews = computed(() => {
    return reviews.value.filter((r) => r.completedAtTimeMs !== undefined);
  });

  const currentCycleReviews = computed(() => {
    return completedReviews.value.filter((review) => {
      return (
        currentCycle.value &&
        review.createdAtTimeMs >= currentCycle.value.startDate &&
        review.createdAtTimeMs <= currentCycle.value.endDate
      );
    });
  });

  const currentCycleReviewsAmount = computed(
    () => currentCycleReviews.value?.length
  );

  const currentCycleExpectedReviewsAmount = computed(() => {
    return currentCycleOrders.value?.length;
  });

  const pendingReviews = computed(() => {
    if (!isReviewPeriodOpen.value) return [];

    return currentCycleOrders.value?.filter((order) => {
      const orderReview = reviews.value.find((r) => r.orderId === order.id);

      if (orderReview?.completedAtTimeMs) return false;

      return true;
    });
  });

  const pendingReviewsAmount = computed(() => pendingReviews.value?.length);

  return {
    reviews,
    completedReviews,
    currentCycleReviews,
    currentCycleReviewsAmount,
    currentCycleExpectedReviewsAmount,
    pendingReviews,
    pendingReviewsAmount,
    isLoading,
    error,
    suspense,
  };
}
