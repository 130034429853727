import { useQuery } from '@tanstack/vue-query';
import { getQuickSurveys } from '../api/requests';
import type { RemoteConfig } from '@tn/shared';

export function useQuickSurveyQuery() {
  const remoteConfig = useState<RemoteConfig>('remote-config');
  const { uid } = useUserState();
  const isQuickSurveysFlagEnabled = computed(
    () => remoteConfig?.value?.quickSurveys?.isEnabled
  );

  return useQuery({
    queryKey: ['quickSurvey'],
    queryFn: async () => {
      const { data } = await getQuickSurveys();

      return data;
    },
    placeholderData: null,
    staleTime: 1000 * 60 * 60, // 1 hour,
    enabled: isQuickSurveysFlagEnabled.value && !!uid.value,
  });
}
