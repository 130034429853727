<script setup lang="ts">
import type { PropType } from 'vue';
import { useAttrs } from 'vue';

type PlacementType =
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'left-start'
  | 'left-end'
  | 'right-start'
  | 'right-end';

const props = defineProps({
  modelValue: {
    type: Date,
    required: false,
    default: undefined,
  },
  disabledDates: {
    type: [Array, Object, Function] as PropType<any | null>,
    default: null,
  },
  icon: {
    type: String,
    required: false,
    default: 'i-heroicons-calendar-days-20-solid',
  },
  mode: {
    type: String,
    required: false,
    default: 'date',
  },
  minDate: {
    type: Date as PropType<Date | null>,
    default: null,
  },

  canShowCloseButton: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits(['update:modelValue', 'update:model-value', 'close']);

const date = computed({
  get: () => props.modelValue ?? undefined,
  set: (value) => {
    emit('update:model-value', value);
    if (!props.canShowCloseButton) {
      emit('close');
    }
  },
});
const attrs = useAttrs();

const placement: PlacementType =
  (attrs.placement as PlacementType) || 'bottom-start';
</script>

<template>
  <UPopover :popper="{ placement: placement }">
    <slot />
    <template #panel="{ close }">
      <div class="flex flex-col">
        <button
          v-if="canShowCloseButton"
          class="left-4 right-4 flex justify-end pr-4 pt-4 text-black"
          @click="close()"
        >
          <UIcon name="i-heroicons-x-mark" class="h-6 w-6" />
        </button>
        <DatePickerBase
          v-model="date"
          :mode="mode"
          :disabled-dates="disabledDates"
          :min-date="minDate"
          @close="!canShowCloseButton ? close() : undefined"
        />
      </div>
    </template>
  </UPopover>
</template>
