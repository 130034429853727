<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: '',
  },

  placeholder: {
    type: String,
    required: false,
    default: '',
  },

  inputType: {
    type: String,
    required: false,
    default: '',
  },

  required: {
    type: Boolean,
    required: false,
    default: false,
  },

  error: {
    type: Boolean,
    required: false,
    default: false,
  },

  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },

  showAsterisk: {
    type: Boolean,
    required: false,
    default: true,
  },

  autoresize: {
    type: Boolean,
    required: false,
    default: false,
  },
  minHeight: {
    type: String,
    required: false,
    default: '',
  },
});

defineEmits(['update:modelValue', 'blur', 'focus']);
</script>

<template>
  <div
    class="flex w-full flex-col rounded-lg border-b-4 border-l border-r border-t border-black px-3 py-2"
    :class="{
      'border-red-400': error,
      'opacity-60': props.disabled,
    }"
  >
    <UTextarea
      :value="modelValue"
      :autoresize="autoresize"
      :placeholder="placeholder"
      :type="inputType"
      size="sm"
      variant="none"
      :padded="false"
      :disabled="props.disabled"
      :required="props.required"
      :style="props.minHeight ? { minHeight: props.minHeight } : {}"
      :ui="{
        base: 'relative block w-full border-none outline-none placeholder:m-0 placeholder:p-0',
        placeholder:
          'placeholder-tnNeutral-400 m-0 p-0 dark:placeholder-gray-500',
        size: {
          sm: 'text-sm font-medium placeholder:text-sm placeholder:font-normal',
        },
      }"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
  </div>
</template>
