import type { UpdateTestClockBody } from './types';

export async function updateTestClock(body: UpdateTestClockBody) {
  return await $fetch('/api/test-clock', {
    method: 'POST',
    body,
  });
}

export async function resetTestClock() {
  return await $fetch('/api/test-clock', {
    method: 'DELETE',
  });
}

export async function getBrandsForTestClock() {
  return await $fetch('/api/brands/private');
}
