import PremiumUpsellModal from '@/components/premium/UpsellModal.vue';
import { useUserReviews } from '~/features/reviews/members';
import { useTokens } from '~/features/tokens';

export const usePremiumCheck = () => {
  const modal = useModal();
  const { lastSeenRenewBannerMs, isPremium, isBrand } = useUserState();
  const { validTokenCount } = useTokens();
  const { pendingReviewsAmount } = useUserReviews();
  const now = useNow();
  const route = useRoute();

  const isExSampler = computed(() => {
    return (
      !isPremium.value &&
      validTokenCount.value < 1 &&
      pendingReviewsAmount.value < 1
    );
  });

  const lostAccessMidCycle = computed(() => {
    return !isPremium.value && validTokenCount.value > 0;
  });

  const openUpsellModal = (section: string) => {
    modal.open(PremiumUpsellModal, {
      section,
    });
  };

  const shouldShowRenewBanner = computed(() => {
    const FOURTEEN_DAYS_IN_MS = 14 * 24 * 60 * 60 * 1000;

    // Brand users should not see the renew banner
    if (isBrand.value) {
      return false;
    }

    // Only show the renew banner on the Homepage and Products pages
    const validRoutes = ['/members', '/members/products'];
    const dynamicPathPrefixes = ['/members/products/'];

    if (
      !validRoutes.includes(route.path) &&
      !dynamicPathPrefixes.some((prefix) => route.path.startsWith(prefix))
    ) {
      return false;
    }

    const lastSeen = lastSeenRenewBannerMs.value ?? 0;

    return !isPremium.value && now.value - lastSeen > FOURTEEN_DAYS_IN_MS;
  });

  return {
    isActivePremium: isPremium,
    isExSampler,
    lostAccessMidCycle,
    openUpsellModal,
    shouldShowRenewBanner,
  };
};
